import React from 'react'

const ResultEntry = ({ title, value }) => (
  <tr>
    <th className="font-weight-normal">
      <small style={{ fontSize: 15 }}>{title}</small>
    </th>
    <td className="font-weight-bold" style={{ fontSize: 18 }}>
      {value}
    </td>
  </tr>
)

export default ResultEntry