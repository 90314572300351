import React from 'react'
import NumberRange from './NumberRange'

import percentageFormatter from '../utilities/percentageFormatter'

class PercentageRange extends React.Component {
  handleChange = (event) => {
    const { onChange } = this.props
    const { target: { name, value } } = event

    onChange({
      target: {
        name,
        value: parseFloat(value)
      }
    })
  }

  render() {
    const { steps, value, onChange: _, ...rangeProps } = this.props
    const formattedSteps = steps.map(step => step.toFixed(1))
    const formattedValue = value.toFixed(1)

    return (
      <NumberRange 
        onChange={this.handleChange}
        steps={formattedSteps}
        value={formattedValue}
        formatter={value => percentageFormatter.format(value / 100)}
        {...rangeProps} 
      />
    )
  }
}

export default PercentageRange