import React from 'react'
import Form from 'react-bootstrap/Form'
import indexOf from 'lodash/indexOf'

import HintIcon from './HintIcon'

class NumberRange extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: indexOf(props.steps, props.value)
    }
  }

  handleChange = (event) => {
    const { onChange, name, steps } = this.props
    const { target: { value } } = event

    this.setState({
      currentStep: value
    })

    onChange({
      target: {
        name,
        value: steps[value]
      }
    })
  }

  render() {
    const { currentStep } = this.state
    const {
      name,
      label,
      value,
      hint,
      formatter,
      onChange: _onChange,
      steps,
      ...inputProps
    } = this.props
    const stepCount = steps.length - 1
    const ratio = currentStep / stepCount
    const percentage = Math.ceil(ratio * 100)

    return (
      <Form.Group controlId={name} className="my-4">
        <Form.Label>
          {label}
          {hint && ' '}
          {hint && <HintIcon hint={hint} />}
        </Form.Label>
        <div className="h1 mb-2">
          {formatter ? formatter(value) : value}
        </div>
        <Form.Control
          type="range"
          bsPrefix="custom-range"
          value={currentStep}
          name={name}
          onChange={this.handleChange}
          min={0}
          max={stepCount}
          style={{
            background: `linear-gradient(to right, #552DFF 0%, #552DFF ${percentage}%, #dee2e6 ${percentage}%, #dee2e6 100%)`
          }}
          {...inputProps}
        />
      </Form.Group>
    )
  }
}

export default NumberRange
