import React from 'react'
import ReactDOM from 'react-dom'

import TaxCalculator from './TaxCalculator'
import HeaderNavbar from './HeaderNavbar'

document.addEventListener('DOMContentLoaded', () => {
  const reactElement = document.getElementById('react')

  if (reactElement) {
    ReactDOM.render(
      <TaxCalculator />,
      reactElement
    )
  }

  ReactDOM.hydrate(
    <HeaderNavbar />,
    document.getElementById('header')
  )
})