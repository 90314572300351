import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

const navLinkProps = (path) => {
  const props = { href: path }

  if (typeof window !== 'undefined' && path === window.location.pathname) {
    props.className = 'active'
  }

  return props
}

const HeaderNavbar = () => (
  <header id="header" className="header">
    <Navbar expand="sm">
      <Navbar.Brand {...navLinkProps('/')}>
        Beregn aktieskat
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="header-navbar" />
      <Navbar.Collapse id="header-navbar">
        <Nav className="ml-auto">
          <Nav.Link {...navLinkProps('/om')}>
            Om beregnaktieskat.dk
          </Nav.Link>
          <Nav.Link {...navLinkProps('/faq')}>
            FAQ
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)



export default HeaderNavbar
