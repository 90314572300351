import React from 'react'
import Form from 'react-bootstrap/Form'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import classnames from 'classnames'

import HintIcon from './HintIcon'

class BooleanToggleField extends React.Component {
  handleChange = value => {
    const { name, onChange } = this.props

    let checked = false
    if (value === 'true') {
      checked = true
    }

    onChange({
      target: {
        name,
        checked
      }
    })
  }

  render() {
    const { name, label, checked, hint, onChange: _, ...inputProps } = this.props

    return (
      <Form.Group controlId={name} className="my-lg-4">
        <Form.Label>
          {label}
          {hint && ' '}
          {hint && <HintIcon hint={hint} />}
        </Form.Label>
        <Row>
          <ToggleButtonGroup
            name={name}
            type="radio"
            as={Col}
            xs={12}
            md={8}
            xl={6}
            onChange={this.handleChange}
            value={`${checked}`}
            {...inputProps}
          >
            <ToggleButton 
              value="true" 
              variant="outline-dark-primary" 
              className="rounded-0"
            >
              Ja
            </ToggleButton>
            <ToggleButton 
              value="false" 
              variant="outline-dark-primary" 
              className="rounded-0"
            >
              Nej
            </ToggleButton>
          </ToggleButtonGroup>
        </Row>
      </Form.Group>
    )
  }
}

export default BooleanToggleField