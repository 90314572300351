import React from 'react'
import classnames from 'classnames'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

const ResultSection = ({ title, children, subheader, className, ...props }) => (
  <Col xs={12} md={6} lg={4}>
    <div className={classnames(className, 'px-5', 'pt-5', 'pb-3', 'mb-5', 'bg-white', 'rounded', 'shadow')} {...props}>
      <h5>{title}</h5>
      <small className="text-muted float-left">{subheader}</small>
      <Table className="mt-5">
        <tbody>
          {children}
        </tbody>
      </Table>
    </div>
  </Col>
)

export default ResultSection
