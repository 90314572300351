import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import Tooltip from 'react-bootstrap/Tooltip'

const HintIcon = ({ hint }) => (
  <OverlayTrigger
    overlay={
      <Tooltip>
        {hint}
      </Tooltip>
    }
  >
    <FontAwesomeIcon icon={faQuestionCircle} />
  </OverlayTrigger>
)

export default HintIcon