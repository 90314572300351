import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Collapse from 'react-bootstrap/Collapse'
import axios from 'axios'
import min from 'lodash/min'
import max from 'lodash/max'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import range from 'lodash/range'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import CurrencyRange from '../components/CurrencyRange'
import PercentageRange from '../components/PercentageRange'
import NumberRange from '../components/NumberRange'
import BooleanToggleField from '../components/BooleanToggleField'
import Section from '../components/Section'
import ResultSection from '../components/ResultSection'
import ResultEntry from '../components/ResultEntry'

import currencyFormatter from '../utilities/currencyFormatter'
import percentageFormatter from '../utilities/percentageFormatter'

const transformKeys = (object, transformFunction) =>
  mapKeys(object, (_value, key) => transformFunction(key))

class TaxCalculator extends React.Component {
  constructor(props) {
    super(props)

    this.rangeSteps = {
      inputValue: this.calculateSteps({ minimum: 10000, maximum: 10000000 }),
      income: this.calculateSteps({ minimum: 0, maximum: 5000000, minimumDecimalNumber: 4 }),
      inputNettokapitalindkomst: this.calculateSteps({ minimum: -500000, maximum: 500000, minimumDecimalNumber: 4 }),
      timeHorizont: range(1, 30 + 1, 1),
      afkast: range(0.5, 20 + 0.1, 0.1),
      varians: range(5, 20 + 0.1, 0.1),
      companyNettoresult: this.calculateSteps({ minimum: -10000000, maximum: 10000000, minimumDecimalNumber: 4 }),
    }

    this.state = {
      loading: false,
      formInput: {
        inputValue: 250000,
        personalIncome: 400000,
        inputNettokapitalindkomst: 0,
        isMarried: false,
        spouseIncome: 400000,
        afkast: 5.5,
        varians: 10.1,
        omsHastighed: 30,
        dividens: 2.4,
        isMemberOfFolkekirke: false,
        companyNettoresult: 0,
        timeHorizont: 10
      },
      result: {},
      isCompany: false,
      advancedSettingsIsOpen: false
    }
  }

  handleSubmit = event => {
    const { formInput } = this.state
    event.preventDefault()

    this.setState({ loading: true })

    const formattedParams = transformKeys(formInput, snakeCase)

    axios.get('/combined_taxes', {
      params: formattedParams
    }).then(response => {
      const { result } = response.data

      const formattedResult = mapValues(
        transformKeys(result, camelCase),
        value => transformKeys(value, camelCase)
      )

      this.setState({ result: formattedResult, loading: false })
    }).catch(error => {
      // handle error
      console.log('ERROR')
      console.log(error)
    })
  }

  handleChangeTextInput = event => {
    const { formInput } = this.state
    const { target: { value, name } } = event

    this.setState({
      formInput: {
        ...formInput,
        [name]: value
      }
    })
  }

  handleChangeBooleanInput = event => {
    const { formInput } = this.state
    const { target: { checked, name } } = event

    this.setState({
      formInput: {
        ...formInput,
        [name]: checked
      }
    })
  }

  handleOpenAdvancedSettings = () => {
    this.setState({ advancedSettingsIsOpen: true })
  }

  handleToggleIsCompany = event => {
    const { formInput } = this.state
    const { target: { checked } } = event

    this.setState({ isCompany: checked, formInput: { ...formInput, companyNettoresult: 0.0 } })
  }

  renderResultSection = ({ data, ...props }) => {
    const { formInput: { afkast } } = this.state
    const yearlyReturnBeforeTax = afkast / 100.0
    const { avgYearlyReturnAfterTax } = data
    const efficientTaxing = -(avgYearlyReturnAfterTax / yearlyReturnBeforeTax - 1.0)

    return (
      <ResultSection {...props}>
        <ResultEntry
          title="Formue ved udløb"
          value={currencyFormatter.format(data.finalWealth)}
        />
        <ResultEntry
          title="Afkast efter skat"
          value={currencyFormatter.format(data.totalReturn)}
        />
        <ResultEntry
          title="Årligt afkast (før skat)"
          value={percentageFormatter.format(yearlyReturnBeforeTax)}
        />
        <ResultEntry
          title="Årligt afkast (efter skat)"
          value={percentageFormatter.format(avgYearlyReturnAfterTax)}
        />
      </ResultSection>
    )
  }

  calculateSteps = ({ minimum, maximum, minimumDecimalNumber }) => {
    const negativeNumbers = minimum < 0
    let startDecimalNumber = Math.abs(minimum).toString().length - 1
    if (negativeNumbers) {
      startDecimalNumber = - startDecimalNumber
    }

    const endDecimalNumber = maximum.toString().length
    let result = []
    if (minimum <= 0) {
      result.push(minimum)
    }

    for (let decimalNumber = startDecimalNumber; decimalNumber < endDecimalNumber; decimalNumber += 1) {
      let newRange
      if (decimalNumber === 0) {
        newRange = [0]
      } else {
        let absoluteDecimalNumber = Math.abs(decimalNumber)
        const step = Math.pow(10, max([absoluteDecimalNumber, minimumDecimalNumber]) - 1)
        let start
        let end

        if (decimalNumber < 0) {
          start = min([max([-Math.pow(10, absoluteDecimalNumber + 1), minimum]), -step])
          end = min([-Math.pow(10, absoluteDecimalNumber), maximum])
        } else {
          start = max([Math.pow(10, absoluteDecimalNumber), minimum, step])
          end = min([Math.pow(10, absoluteDecimalNumber + 1), maximum])
        }

        newRange = range(start, end, step)
      }


      result = result.concat(newRange)
    }

    result.push(maximum)

    return result
  }

  render() {
    const {
      formInput: {
        inputValue,
        personalIncome,
        inputNettokapitalindkomst,
        isMarried,
        spouseIncome,
        afkast,
        varians,
        omsHastighed,
        timeHorizont,
        dividens,
        isMemberOfFolkekirke,
        companyNettoresult
      },
      isCompany,
      result,
      loading,
      advancedSettingsIsOpen
    } = this.state

    return (
      <React.Fragment>
        <Row className="tax-calculator-form">
          <Col xs={12} lg={{ offset: 2, span: 8 }}>
            <Form onSubmit={this.handleSubmit}>
              <Section>
                <Row>
                  <Col>
                    <CurrencyRange
                      name="inputValue"
                      label="Investeret beløb"
                      // hint="Angiv det beløb, som du forventer at investere, og som du gerne vil foretage beregningen på."
                      value={inputValue}
                      onChange={this.handleChangeTextInput}
                      steps={this.rangeSteps.inputValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CurrencyRange
                      name="personalIncome"
                      label="Personlig indkomst (årlig)"
                      hint="Angiv din årlige personlige indkomst. Det er din lønindkomst fratrukket arbejdsmarkedsbidrag, pensionsudbetalinger m.m. Din personlige indkomst kan have betydning for beskatning af kapitalindkomst."
                      value={personalIncome}
                      onChange={this.handleChangeTextInput}
                      steps={this.rangeSteps.income}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CurrencyRange
                      name="inputNettokapitalindkomst"
                      label="Netto kapitalindkomst (årlig)"
                      hint="Angiv din nettokapitalindkomst (excl. eventuelt afkast fra denne investering). Din kapitalindkomst består typisk af renteudgifter, renteindtægter eller indtægter fra fx udlejning af værelse eller sommerhus. Du vil kunne se beløbet på din årsopgørelse fra Skat, men du skal være opmærksom på at det naturligvis kan være anderledes fremover. Er du gift skal din ægtefælles kapitalindkomst også inkluderes."
                      value={inputNettokapitalindkomst}
                      onChange={this.handleChangeTextInput}
                      steps={this.rangeSteps.inputNettokapitalindkomst}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <NumberRange
                      name="timeHorizont"
                      label="Investeringshorisont"
                      // hint="Indtast det antal år som du forventer er din investeringshorisont og gerne vil estimere beregningen på baggrund af."
                      hint="Indtast det antal år som du forventer at være investeret."
                      value={timeHorizont}
                      onChange={this.handleChangeTextInput}
                      steps={this.rangeSteps.timeHorizont}
                      formatter={value => `${value} år`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PercentageRange
                      name="afkast"
                      label="Forventet årligt afkast"
                      hint="Indtast afkastet som du forventer din investering vil give og gerne vil anvende til estimering af resultatet. Afkastet skal opgives som årligt afkast. Vi anvender som standard 5,5 pct. som er Forsikring & Pensions forventede afkast for globale aktier de næste 10 år."
                      value={afkast}
                      onChange={this.handleChangeTextInput}
                      steps={this.rangeSteps.afkast}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PercentageRange
                      name="varians"
                      label="Risiko – Standardafvigelse"
                      hint="Indtast standardafvigelsen for din investering og som du gerne vil anvende til estimering af resultatet. Vi anvender som standard 10,1 pct. som er Forsikring & Pensions forventede standardafvigelse for globale aktier de næste 10 år."
                      value={varians}
                      onChange={this.handleChangeTextInput}
                      steps={this.rangeSteps.varians}
                    />
                  </Col>
                </Row>
              </Section>
              <Section>
                <Row>
                  <Col>
                    <BooleanToggleField
                      name="isMemberOfFolkekirke"
                      label="Medlem af folkekirken?"
                      hint="Angiv om du er medlem af folkekirken. Det har betydning for beskatning af kapitalindkomst."
                      checked={isMemberOfFolkekirke}
                      onChange={this.handleChangeBooleanInput}
                    />
                  </Col>
                </Row>
              </Section>
              <Section>
                <Row>
                  <Col>
                    <BooleanToggleField
                      name="isMarried"
                      label="Gift?"
                      hint="Angiv om du er gift. Det har betydning for beskatning af kapitalindkomst."
                      checked={isMarried}
                      onChange={this.handleChangeBooleanInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Collapse in={isMarried}>
                      <div>
                        <CurrencyRange
                          name="spouseIncome"
                          label="Ægtefælles personlige indkomst (årlig)"
                          hint="Angiv din ægtefælles årlige personlige indkomst. Det er lønindkomst fratrukket arbejdsmarkedsbidrag, pensionsudbetalinger m.m. Din ægtefælles personlige indkomst kan have betydning for beskatning af kapitalindkomst."
                          value={spouseIncome}
                          onChange={this.handleChangeTextInput}
                          steps={this.rangeSteps.income}
                        />
                      </div>
                    </Collapse>
                  </Col>
                </Row>
              </Section>
              <Section>
                <Row>
                  <Col>
                    <BooleanToggleField
                      name="isCompany"
                      label="Investerer du igennem en virksomhed?"
                      checked={isCompany}
                      onChange={this.handleToggleIsCompany}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Collapse in={isCompany}>
                      <div>
                        <CurrencyRange
                          name="companyNettoresult"
                          label="Virksomhed nettoresultat"
                          hint="Angiv nettoresultat fra din virksomhed, hvis du planlægger investeringen via din virksomhed. Du skal taste nettoresultatet excl. eventuelt afkast fra denne investering. Dette er kun relevant, hvis din virksomhed følger selskabsbeskatningen."
                          value={companyNettoresult}
                          onChange={this.handleChangeTextInput}
                          steps={this.rangeSteps.companyNettoresult}
                        />
                      </div>
                    </Collapse>
                  </Col>
                </Row>
              </Section>
              <Collapse in={!advancedSettingsIsOpen}>
                <Row>
                  <Col className="text-center">
                    <Button
                      variant="link"
                      className="p-0 mb-5 mt-6 text-dark font-weight-bolder font-size-lg"
                      onClick={this.handleOpenAdvancedSettings}
                      aria-controls="return-settings"
                      aria-expanded={advancedSettingsIsOpen}
                    >
                      Avancerede indstillinger
                      {' '}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </Button>
                  </Col>
                </Row>
              </Collapse>
              <Collapse in={advancedSettingsIsOpen}>
                <Section id="return-settings">
                  <Row>
                    <Col>
                      <PercentageRange
                        name="dividens"
                        label="Udbytte"
                        hint="Mange aktier udbetaler udbytte og investerer du i en danske udbyttebetalende investeringsfond, vil fonden udbetale udbyttet til dig. Vi har som standard sat udbyttet til 2,4 pct., men køber du en investeringsfond, der investerer i aktier med højt eller begrænset udbytte, kan du justere udbyttet."
                        value={dividens}
                        onChange={this.handleChangeTextInput}
                        steps={range(0, 20 + 1, 0.1)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <PercentageRange
                        name="omsHastighed"
                        label="Omsætningshastighed"
                        hint="Angiv den forventede omsætningshastighed i den fond, som du eventuelt planlægger at investere i. Vil du investere i en fond som køber og sælger aktier ofte, vil omsætningshastigheden typisk være høj, hvorimod en begrænset handelsaktivitet giver en lavere omsætningshastighed. Vi har som standard sat omsætningshastigheden til 30 pct. Det svarer til at 30 pct. af investeringsfondens formue handles på i løbet af et år."
                        value={omsHastighed}
                        onChange={this.handleChangeTextInput}
                        formatter={value => `${parseInt(value)} %`}
                        steps={range(0, 200 + 1, 1)}
                      />
                    </Col>
                  </Row>
                </Section>
              </Collapse>

              <Row>
                <Col className="text-center">
                  <Button variant="primary" type="submit" className="px-5" size="lg" disabled={loading}>
                    Beregn aktieskat
                    {loading && ' '}
                    {loading && (
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Collapse in={Object.keys(result).length > 0}>
              <div id="tax-result">
                {Object.keys(result).length > 0 && (
                  <Row>
                    {this.renderResultSection({ title: 'Aktieindkomst', data: result.aktieindkomst })}
                    {this.renderResultSection({
                      title: (
                        <React.Fragment>
                          <span>Ny aktieindkomst </span>
                          <Badge variant="primary">
                            <small>Fra 2020</small>
                          </Badge>
                        </React.Fragment>
                      ),
                      subheader: 'For investeringsselskaber bl.a. ETF’ere.',
                      data: result.udlfondeAktieindkomst
                    })}
                    {this.renderResultSection({ title: 'Kapitalindkomst', data: result.kapitalindkomst })}
                    {this.renderResultSection({ title: 'Pensionsbeskatning (PAL)', data: result.palSkat })}
                    {this.renderResultSection({ title: 'Selskabsbeskatning', data: result.selskabsbeskatning })}
                  </Row>
                )}
              </div>
            </Collapse>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default TaxCalculator
